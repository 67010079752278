import React from "react";
import BlogTeaser from "../../components/blog-teaser";
import Section from "../../components/section";
import { Container, useTheme, Theme, Box, Typography, Button } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import PackageBoxes from "../../components/package-boxes";
import Hero from "../../components/hero";
import styled from "@emotion/styled";
import PackageCompare from "../../components/package-compare";
import FaqBoxes from "../../components/faq-boxes";
import PackageCustom from "../../components/package-custom";
import SEO from "../../components/seo";
import MainLayout from "../../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const NegMargnSection = styled(Section)((props: { theme: Theme }) => `
    margin-top: -185px;
    z-index: 2;
    position:relative;

    ${props.theme.breakpoints.down("sm")} {
        margin-top: -125px;
    }
`);

const NeuroAcademyIndividualsPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
    query {
        seo: individualPackagesJson(block: {eq: "seo" }) {
            title
            description
            image {
                childImageSharp {
                    resize(width: 750, height: 300, cropFocus: ENTROPY) {
                        src
                        width
                        height
                    }
                }
            }
        }
        hero: individualPackagesJson(block: {eq: "hero"}) {
            title
            tagline
            ctaText
            ctaUrl
            cta2Text
            cta2Url
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        packageBlock: individualPackagesJson(block: {eq: "packages"}) {
            packages {
                name
                price
                priceUnit
                ctaLink
                promoted
                description
                features
            }
            features {
                group
                name
                available {
                    pkg
                }
            }
        }
        faqBlock: individualPackagesJson(block: {eq: "faq" }) {
            title
            subtitle
            questions {
                question
                answer
            }
        }
    }
    `);

    return (<MainLayout transparentNav>
        <SEO {...data.seo} location={props.location} />
        <Hero heroHeight={65} overlayColor={theme.palette.primary.dark} {...data.hero} />
        <NegMargnSection theme={theme}>
            <Container>
                <PackageBoxes packages={data.packageBlock.packages} />
                <Box pt={4}>
                    <Typography align="center" variant="subtitle2">
                        Looking for packages for organizations?
                        <Button variant="text" color="primary" size="medium" component={Link} to="/neuro-academy/organizations">
                            Goto Organizations
                        </Button>
                    </Typography>
                </Box>
            </Container>
        </NegMargnSection>

        <Section id="compare" bgcolor={theme.palette.secondary.light}>
            <Container maxWidth="md">
                <PackageCompare {...data.packageBlock} />
            </Container>
        </Section>

        <Section>
            <Container>
                <FaqBoxes {...data.faqBlock} />
            </Container>
        </Section>

        {/* <Section id="custom" bgcolor={theme.palette.primary.main}>
            <PackageCustom />
        </Section> */}

        <BlogTeaser hideSubscribe />
    </MainLayout>);
};

export default NeuroAcademyIndividualsPage;